import React, { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { getStockMapDataAPI, getPlateCountDataAPI, getPlateStockCountAPI } from '../../../apis/house'
import Dayjs from 'dayjs'

export default function StockMap() {
  const [typeIndex, setTypeIndex] = useState('0')
  let typeList = [
    {
      key: '0',
      label: '区域',
      children: <DistrictMap type={typeIndex} />
    },
    {
      key: '1',
      label: '板块商圈',
      children: <DistrictMap type={typeIndex} />
    }
  ]
  const changeTabs = key => {
    setTypeIndex(key)
  }
  useEffect(() => {}, [])
  return (
    <div className="tabs h-full flex-col">
      <div className="pageTitle">库存地图</div>
      <div className="flex-1">
        <div className="h-full overflow-y">
          <Tabs activeKey={typeIndex} items={typeList} onTabClick={changeTabs} />
        </div>
      </div>
    </div>
  )
}

function DistrictMap(props) {
  const [type, setType] = useState(0)
  let map = null
  const TMap = window.TMap

  const mapDom = useRef(null)
  const [tabsIndex, setTabsIndex] = useState(0)
  const [list, setList] = useState([])
  const [listIndex, setListIndex] = useState()

  // let tabsList = [
  //   {
  //     key: '0',
  //     label: '取证库存',
  //     children: <RankList list={list} tabs={tabsIndex} />
  //   },
  //   {
  //     key: '1',
  //     label: '报规库存',
  //     children: <RankList list={list} tabs={tabsIndex} />
  //   }
  // ]

  const getPlateCountList = async type => {
    const res = await getStockMapDataAPI({ type })
    if (res.success === true && res.result) {
      //    窗体
      res.result.forEach(item => {
        item.id = Number(type) === 1 ? item.plateId : item.districtId
        item.name = Number(type) === 1 ? item.plate : item.district
      })
      setList(res.result)
      showMapMarkets({ list: res.result, type })
    }
  }
  //  地图窗口信息显示
  window.dstClick = async index => {
    setListIndex(index)
    let markerInfo = list[index] || {}
    document.querySelectorAll('.dst-box').forEach(item => {
      item.parentNode.style.zIndex = 0
    })
    document.querySelector('#dst' + markerInfo.id).parentNode.style.zIndex = 999
    if (!markerInfo.marker) {
      markerInfo.marker = {}
      //  获取区域板块
      const res = await getPlateCountDataAPI({ districtId: markerInfo.districtId, plateId: markerInfo.plateId })
      if (res.success === true && res.result) {
        markerInfo.marker.monthSoldNum = res.result.monthSoldNum
        markerInfo.marker.soldCycle = res.result.soldCycle
        markerInfo.marker.dynamicHouseNum = res.result.dynamicHouseNum
        markerInfo.marker.monthSoldEval = 0
        markerInfo.marker.soldCycleEval = 0
        markerInfo.marker.dynamicHouseEval = 0
        if (markerInfo.plateId > 0) {
          // 板块
          //  月成交
          if (res.result.monthSoldRank <= 10) {
            markerInfo.marker.monthSoldEval = 1
          } else if (res.result.monthSoldRank <= 25) {
            markerInfo.marker.monthSoldEval = 2
          } else if (res.result.monthSoldRank <= 45) {
            markerInfo.marker.monthSoldEval = 3
          } else {
            markerInfo.marker.monthSoldEval = 0
          }
          //  去化周期
          if (res.result.soldCycleRank <= 10) {
            markerInfo.marker.soldCycleEval = 1
          } else if (res.result.soldCycleRank <= 25) {
            markerInfo.marker.soldCycleEval = 2
          } else if (res.result.soldCycleRank <= 45) {
            markerInfo.marker.soldCycleEval = 3
          } else {
            markerInfo.marker.soldCycleEval = 0
          }
          //  活跃楼盘
          if (res.result.dynamicHouseRank <= 10) {
            markerInfo.marker.dynamicHouseEval = 1
          } else if (res.result.dynamicHouseRank <= 25) {
            markerInfo.marker.dynamicHouseEval = 2
          } else if (res.result.dynamicHouseRank <= 45) {
            markerInfo.marker.dynamicHouseEval = 3
          } else {
            markerInfo.marker.dynamicHouseEval = 0
          }
        } else {
          //  区域
          //  月成交
          if (res.result.monthSoldRank <= 3) {
            markerInfo.marker.monthSoldEval = 1
          } else if (res.result.monthSoldRank <= 8) {
            markerInfo.marker.monthSoldEval = 2
          } else if (res.result.monthSoldRank <= 12) {
            markerInfo.marker.monthSoldEval = 3
          } else {
            markerInfo.marker.monthSoldEval = 0
          }
          //  去化周期
          if (res.result.soldCycleRank <= 3) {
            markerInfo.marker.soldCycleEval = 1
          } else if (res.result.soldCycleRank <= 8) {
            markerInfo.marker.soldCycleEval = 2
          } else if (res.result.soldCycleRank <= 12) {
            markerInfo.marker.soldCycleEval = 3
          } else {
            markerInfo.marker.soldCycleEval = 0
          }
          //  活跃楼盘
          if (res.result.dynamicHouseRank <= 3) {
            markerInfo.marker.dynamicHouseEval = 1
          } else if (res.result.dynamicHouseRank <= 8) {
            markerInfo.marker.dynamicHouseEval = 2
          } else if (res.result.dynamicHouseRank <= 12) {
            markerInfo.marker.dynamicHouseEval = 3
          } else {
            markerInfo.marker.dynamicHouseEval = 0
          }
        }
      }
      let curYear = new Date().getFullYear()
      let curMonth = new Date().getMonth() + 1
      let startYear = curMonth < 3 ? curYear - 1 : curYear
      let startMonth = curMonth < 3 ? curMonth - 2 + 12 : curMonth - 2
      startMonth = startMonth < 10 ? '0' + startMonth : startMonth
      //  获取库存数量
      const stockCount = await getPlateStockCountAPI({ districtId: markerInfo.districtId, plateId: markerInfo.plateId, startTime: startYear + '-' + startMonth + '- 01', endTime: Dayjs().format('YYYY-MM-DD') })
      if (stockCount.success === true && stockCount.result) {
        markerInfo.marker.evidenceRoomNum = stockCount.result.evidenceRoomNum // 取证库存
        markerInfo.marker.evidenceSoldCycle = stockCount.result.evidenceSoldCycle // 取证库存去化周期
        markerInfo.marker.stockNum = stockCount.result.stockNum // 报规库存
        markerInfo.marker.soldCycle = stockCount.result.soldCycle // 报规库存去化周期
      }
      //  获取住宅成交量
      list[index] = markerInfo
      setList(list)
    }
    document.querySelector('#dst' + markerInfo.id + ' .hotHouseEval').innerText = markerInfo.marker.dynamicHouseEval === 1 ? '优秀' : markerInfo.marker.dynamicHouseEval === 2 ? '良好' : markerInfo.marker.dynamicHouseEval === 3 ? '一般' : '较差' //  活跃楼盘评比文本
    document.querySelector('#dst' + markerInfo.id + ' .hotHouseEval').style.color = markerInfo.marker.dynamicHouseEval === 1 ? '#07c160' : markerInfo.marker.dynamicHouseEval === 2 ? '#1485ee' : markerInfo.marker.dynamicHouseEval === 3 ? '#6467f0' : '#ffc300' //  活跃楼盘评比颜色
    document.querySelector('#dst' + markerInfo.id + ' .hotHouseNum').innerText = markerInfo.marker.dynamicHouseNum + '个' // 活跃楼盘数量
    document.querySelector('#dst' + markerInfo.id + ' .monthSoldEval').innerText = markerInfo.marker.monthSoldEval === 1 ? '优秀' : markerInfo.marker.monthSoldEval === 2 ? '良好' : markerInfo.marker.monthSoldEval === 3 ? '一般' : '较差' //  月成交评比文本
    document.querySelector('#dst' + markerInfo.id + ' .monthSoldEval').style.color = markerInfo.marker.monthSoldEval === 1 ? '#07c160' : markerInfo.marker.monthSoldEval === 2 ? '#1485ee' : markerInfo.marker.monthSoldEval === 3 ? '#6467f0' : '#ffc300' //  月成交评比颜色
    document.querySelector('#dst' + markerInfo.id + ' .monthSoldNum').innerText = markerInfo.marker.monthSoldNum + '套' // 月成交数量
    document.querySelector('#dst' + markerInfo.id + ' .evidenceSoldCycleEval').innerText = markerInfo.marker.soldCycleEval === 1 ? '优秀' : markerInfo.marker.soldCycleEval === 2 ? '良好' : markerInfo.marker.soldCycleEval === 3 ? '一般' : '较差' //  去化周期评比文本
    document.querySelector('#dst' + markerInfo.id + ' .evidenceSoldCycleEval').style.color = markerInfo.marker.soldCycleEval === 1 ? '#07c160' : markerInfo.marker.soldCycleEval === 2 ? '#1485ee' : markerInfo.marker.soldCycleEval === 3 ? '#6467f0' : '#ffc300' //  去化周期比颜色
    document.querySelector('#dst' + markerInfo.id + ' .evidenceSoldCycle').innerText = markerInfo.marker.evidenceSoldCycle + '月' // 去化周期
    document.querySelector('#dst' + markerInfo.id + ' .evidenceStockNum').innerText = markerInfo.marker.evidenceRoomNum + '套' // 取证库存
    document.querySelector('#dst' + markerInfo.id + ' .stockNum').innerText = markerInfo.marker.stockNum + '套' // 报规库存
    document.querySelector('#dst' + markerInfo.id + ' .stockSoldCycle').innerText = markerInfo.marker.soldCycle + '月' // 报规库存去化周期
    document.querySelectorAll('.dst-box').forEach(item => (item.style.display = 'none'))
    document.querySelector('#dst' + markerInfo.id).style.display = 'block'
  }

  //  地图窗口信息隐藏
  window.dstHide = index => {
    let markerInfo = list[index] || {}
    document.querySelector('#dst' + markerInfo.id).style.display = 'none'
  }

  //    地图渲染
  const showMapMarkets = data => {
    data.list.forEach((item, index) => {
      let styleDistrict = 'style="background:#ffffff; display: flex; align-items: center; border-radius: 5px; line-height: 24px;"'
      let styleRank = 'color: #ffffff; font-size: 10px; font-weight: 600; padding: 0 6px; border-radius: 5px;'
      let styleName = 'style="font-size: 14px; padding: 0 10px; font-weight: 600;"'
      let styleWindows = 'style="display:none; position: absolute; top: 26px; background:#ffffff; width: 162px; height: 174px; border-radius: 5px; padding: 10px; box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); font-size: 12px; z-index: 99999"'
      let styleWindowsInfo = 'style="display: flex; align-items: center; justify-content: space-between; line-height: 18px; margin-bottom: 4px;"'
      let content = '<div style="position:relative;">'
      //  常显标记
      content += '<div ' + styleDistrict + ' onmouseover="dstClick(' + index + ')" onmouseout="dstHide(' + index + ')">'
      if (Number(data.type) === 1) {
        if (item.soldCycleRank <= 10) {
          content += '<div style="' + styleRank + ' background: #07C160;">优秀</div>'
        } else if (item.soldCycleRank <= 25) {
          content += '<div style="' + styleRank + ' background: #1485ee;">良好</div>'
        } else if (item.soldCycleRank <= 45) {
          content += '<div style="' + styleRank + ' background: #6467f0;">一般</div>'
        } else {
          content += '<div style="' + styleRank + ' background: #ffc300;">较差</div>'
        }
      } else {
        if (item.soldCycleRank <= 3) {
          content += '<div style="' + styleRank + ' background: #07C160;">优秀</div>'
        } else if (item.soldCycleRank <= 8) {
          content += '<div style="' + styleRank + ' background: #1485ee;">良好</div>'
        } else if (item.soldCycleRank <= 12) {
          content += '<div style="' + styleRank + ' background: #6467f0;">一般</div>'
        } else {
          content += '<div style="' + styleRank + ' background: #ffc300;">较差</div>'
        }
      }
      content += '<div ' + styleName + '>' + item.name + '</div>'
      content += '</div>'
      /*** 点击显示标记 START ***/
      content += '<div id="dst' + item.id + '" class="dst-box" ' + styleWindows + ' onmouseover="dstClick(' + index + ')" onmouseout="dstHide(' + index + ')">'
      content += '<div ' + styleWindowsInfo + '><div>活跃楼盘<span style="font-size: 10px; margin-left: 4px;" class="hotHouseEval">优秀</span></div><div style="color: #666666;" class="hotHouseNum">48个</div></div>'
      content += '<div ' + styleWindowsInfo + '><div>月均成交<span style="font-size: 10px; margin-left: 4px;" class="monthSoldEval">优秀</span></div><div style="color: #666666;" class="monthSoldNum">595套</div></div>'
      content += '<div ' + styleWindowsInfo + '><div>取证库存</div><div style="color: #666666;" class="evidenceStockNum">12471套</div></div>'
      content += '<div ' + styleWindowsInfo + '><div>去化周期<span style="font-size: 10px; margin-left: 4px;" class="evidenceSoldCycleEval">优秀</span></div><div style="color: #666666;" class="evidenceSoldCycle">31月</div></div>'
      content += '<div ' + styleWindowsInfo + '><div>报规库存</div><div style="color: #666666;" class="stockNum">18719套</div></div>'
      content += '<div ' + styleWindowsInfo + '><div>去化周期</div><div style="color: #666666;" class="stockSoldCycle">31月</div></div>'
      content += '<a href="/#/managecenter/supplymarket/district?districtid=' + item.districtId + '&plateid=' + item.plateId + '" style="color: #576B95; margin-top: 6px; line-height: 22px;">查看区域详情</a>'
      content += '</div>'
      /*** 点击显示标记 END ***/
      content += '</div>'

      new TMap.InfoWindow({
        map,
        enableCustom: true,
        position: new TMap.LatLng(item.lat, item.lng),
        content: content
      })
    })
  }

  //  tabs页切换
  const onChange = activeKey => {
    console.log('%c [ activeKey ]-82', 'font-size:13px; background:pink; color:#bf2c9f;', activeKey)
    if (tabsIndex === activeKey) {
      return false
    }
    setTabsIndex(activeKey)
  }

  //  切换信息窗口
  // const changeMarkers = index => {
  //   console.log('%c [ index ]-90', 'font-size:13px; background:pink; color:#bf2c9f;', index)
  // }

  useEffect(() => {
    if (props.type !== type) {
      setType(props.type)
      getPlateCountList(props.type)
      //  定义地图中心点坐标
      var center = new TMap.LatLng(30.606531, 114.31618)

      map = new TMap.Map(mapDom.current, {
        center: center,
        zoom: 10,
        mapStyleId: 'style1',
        viewMode: '2D'
      })
    }
  }, [type])

  return (
    <div className="stock-map flex-1">
      <div className="data-list">
        <div className="data-tabs">
          <div className={tabsIndex === 0 ? 'data-tabs-item cur' : 'data-tabs-item'} onClick={() => onChange(0)}>
            取证库存
          </div>
          <div className={tabsIndex === 1 ? 'data-tabs-item cur' : 'data-tabs-item'} onClick={() => onChange(1)}>
            报规库存
          </div>
        </div>
        <div className="stock-map-list">
          {list.map((ele, index) => {
            return (
              <div className={listIndex === index ? 'item cur' : 'item'} key={index} onClick={() => window.dstClick(index)}>
                <div className="item-info">
                  <div className="name">{Number(type) === 1 ? ele.district + '-' + ele.name : ele.name}</div>
                  {tabsIndex === 1 ? (
                    <div className="data">
                      {ele.stockNum}套/{ele.stockSoldCycle}月
                    </div>
                  ) : (
                    <div className="data">
                      {ele.evidenceStockNum}套/{ele.evidenceSoldCycle}月
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div ref={mapDom} style={{ width: '100%', height: '100%' }} />
    </div>
  )
}
