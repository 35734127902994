import React from 'react'
import { Card, Col, Row, DatePicker, Table, Pagination, Space } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getDistrictListAPI } from '../../../../apis/common'
import { getPlateCountDataAPI, getPlateHotHouseListAPI, getPlateStockCountAPI, getPlateSupplyCountAPI, getPlateSoldCountAPI } from '../../../../apis/house'
import styles from '../district.module.scss'
import Dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

Dayjs.locale('zh-cn')
const { RangePicker } = DatePicker
const { Column } = Table

export default function PlateInfo(props) {
  const navigate = useNavigate()
  const districtId = props.districtId

  const [plateId, setPlateId] = useState(props.plateId || 0)
  const [plateTabsList, setPlateTabsList] = useState([
    {
      id: 0,
      name: '全区'
    }
  ])
  const [districtInfo, setDistrictInfo] = useState({})
  const [totalDistrict, setTotalDistrict] = useState(0)
  const [totalPlate, setTotalPlate] = useState(0)

  /** 活跃楼盘参数 **/
  const [tableLoading, setTableLoading] = useState(false) //  表格加载状态
  //  活跃楼盘列表请求参数
  const [tableListQuery, setTableListQuery] = useState({
    districtId: districtId,
    plateId: plateId,
    pageInt: 1,
    pageSize: 10
  })
  const [tableTotal, setTableTotal] = useState(0) //  活跃楼盘列表总数
  const [hotHouseList, setHotHouseList] = useState([]) //  活跃楼盘列表

  let curYear = new Date().getFullYear()
  let curMonth = new Date().getMonth() + 1
  let startYear = curMonth < 3 ? curYear - 1 : curYear
  let startMonth = curMonth < 3 ? curMonth - 2 + 12 : curMonth - 2
  startMonth = startMonth < 10 ? '0' + startMonth : startMonth

  /** 住宅成交数据参数 **/
  //  请求参数
  const [soldDataQuery, setSoldDataQuery] = useState({
    districtId: districtId,
    plateId: plateId,
    startTime: startYear + '-' + startMonth + '- 01',
    endTime: Dayjs().format('YYYY-MM-DD')
  })
  const [soldData, setSoldData] = useState({
    totalPriceList: [],
    unitPriceList: [],
    areaList: []
  }) //  住宅成交数据

  /** 住宅供应数据参数 **/
  //  请求参数
  const [supplyDataQuery, setSupplyDataQuery] = useState({
    districtId: districtId,
    plateId: plateId,
    startTime: startYear + '-' + startMonth + '- 01',
    endTime: Dayjs().format('YYYY-MM-DD')
  })
  const [supplyData, setSupplyData] = useState({
    totalPriceList: [],
    unitPriceList: [],
    areaList: []
  })

  /** 住宅库存数据参数 **/
  //  请求参数
  const stockDataQuery = {
    districtId: districtId,
    plateId: plateId,
    startTime: startYear + '-' + startMonth + '- 01',
    endTime: Dayjs().format('YYYY-MM-DD')
  }
  // const [stockDataQuery, setStockDataQuery] = useState({
  //   districtId: districtId,
  //   plateId: plateId,
  //   startTime: startYear + '-' + startMonth + '- 01',
  //   endTime: Dayjs().format('YYYY-MM-DD')
  // })
  const [stockData, setStockData] = useState({
    totalPriceList: [],
    unitPriceList: [],
    areaList: []
  })

  //  获取统计数据
  const fetchData = async plateId => {
    const res = await getPlateCountDataAPI({ districtId, plateId })
    if (res.success === true) {
      if (res.result) {
        res.result.monthSoldRankTxt = ''
        res.result.soldCycleRankTxt = ''
        res.result.dynamicHouseRankTxt = ''
        res.result.bgStyle = ''
        if (plateId > 0) {
          // 板块
          //  月成交
          if (res.result.monthSoldRank <= 10) {
            res.result.monthSoldRankTxt = '优秀'
          } else if (res.result.monthSoldRank <= 25) {
            res.result.monthSoldRankTxt = '良好'
          } else if (res.result.monthSoldRank <= 45) {
            res.result.monthSoldRankTxt = '一般'
          } else {
            res.result.monthSoldRankTxt = '较差'
          }
          //  去化周期
          if (res.result.soldCycleRank <= 10) {
            res.result.soldCycleRankTxt = '优秀'
            res.result.bgStyle = 'bg-excellent'
          } else if (res.result.soldCycleRank <= 25) {
            res.result.soldCycleRankTxt = '良好'
            res.result.bgStyle = 'bg-good'
          } else if (res.result.soldCycleRank <= 45) {
            res.result.soldCycleRankTxt = '一般'
            res.result.bgStyle = 'bg-commonly'
          } else {
            res.result.soldCycleRankTxt = '较差'
            res.result.bgStyle = 'bg-poor'
          }
          //  活跃楼盘
          if (res.result.dynamicHouseRank <= 10) {
            res.result.dynamicHouseRankTxt = '优秀'
          } else if (res.result.dynamicHouseRank <= 25) {
            res.result.dynamicHouseRankTxt = '良好'
          } else if (res.result.dynamicHouseRank <= 45) {
            res.result.dynamicHouseRankTxt = '一般'
          } else {
            res.result.dynamicHouseRankTxt = '较差'
          }
        } else {
          //  区域
          //  月成交
          if (res.result.monthSoldRank <= 3) {
            res.result.monthSoldRankTxt = '优秀'
          } else if (res.result.monthSoldRank <= 8) {
            res.result.monthSoldRankTxt = '良好'
          } else if (res.result.monthSoldRank <= 12) {
            res.result.monthSoldRankTxt = '一般'
          } else {
            res.result.monthSoldRankTxt = '较差'
          }
          //  去化周期
          if (res.result.soldCycleRank <= 3) {
            res.result.soldCycleRankTxt = '优秀'
            res.result.bgStyle = 'bg-excellent'
          } else if (res.result.soldCycleRank <= 8) {
            res.result.soldCycleRankTxt = '良好'
            res.result.bgStyle = 'bg-good'
          } else if (res.result.soldCycleRank <= 12) {
            res.result.soldCycleRankTxt = '一般'
            res.result.bgStyle = 'bg-commonly'
          } else {
            res.result.soldCycleRankTxt = '较差'
            res.result.bgStyle = 'bg-poor'
          }
          //  活跃楼盘
          if (res.result.dynamicHouseRank <= 3) {
            res.result.dynamicHouseRankTxt = '优秀'
          } else if (res.result.dynamicHouseRank <= 8) {
            res.result.dynamicHouseRankTxt = '良好'
          } else if (res.result.dynamicHouseRank <= 12) {
            res.result.dynamicHouseRankTxt = '一般'
          } else {
            res.result.dynamicHouseRankTxt = '较差'
          }
        }
        setDistrictInfo(res.result)
      }
    }
  }
  //  获取区域板块列表
  const getDistrictList = async () => {
    const res = await getDistrictListAPI()
    if (res.success === true) {
      if (res.result) {
        let tabsList = [
          {
            id: 0,
            name: '全区'
          }
        ]
        let totalDistrict = 0
        let totalPlate = 0
        res.result.forEach(item => {
          if (item.parentId === districtId) {
            tabsList.push({
              id: item.id,
              name: item.name
            })
          }
          if (item.parentId === 0) {
            totalDistrict++
          } else {
            totalPlate++
          }
        })
        setTotalDistrict(totalDistrict)
        setTotalPlate(totalPlate)
        setPlateTabsList(tabsList)
      }
    }
  }
  //  获取活跃楼盘列表
  const getHotHouseList = async () => {
    setTableLoading(true)
    const res = await getPlateHotHouseListAPI(tableListQuery)
    if (res.success === true) {
      if (res.result && res.result.list) {
        res.result.list.forEach(item => {
          item.newEvidenceTime = Dayjs(item.newEvidenceTime).format('YYYY-MM-DD')
        })
        setHotHouseList(res.result.list)
        setTableTotal(res.result.total)
      }
      setTableLoading(false)
    }
  }
  //  获取住宅成交数据
  const getSoldData = async () => {
    const res = await getPlateSoldCountAPI(soldDataQuery)
    if (res.success === true) {
      if (res.result) {
        if (res.result.totalPriceList) {
          let totalPriceMax = 0
          res.result.totalPriceList.forEach(item => {
            totalPriceMax = Math.max(item.roomNum, totalPriceMax)
          })
          res.result.totalPriceMax = totalPriceMax
        }
        if (res.result.unitPriceList) {
          let unitPriceMax = 0
          res.result.unitPriceList.forEach(item => {
            unitPriceMax = Math.max(item.roomNum, unitPriceMax)
          })
          res.result.unitPriceMax = unitPriceMax
        }
        if (res.result.areaList) {
          let areaeMax = 0
          res.result.areaList.forEach(item => {
            areaeMax = Math.max(item.roomNum, areaeMax)
          })
          res.result.areaeMax = areaeMax
        }
        setSoldData(res.result)
      }
    }
  }
  //  获取住宅供应数据
  const getSupplyData = async () => {
    const res = await getPlateSupplyCountAPI(supplyDataQuery)
    if (res.success === true) {
      if (res.result) {
        if (res.result.totalPriceList) {
          let totalPriceMax = 0
          res.result.totalPriceList.forEach(item => {
            totalPriceMax = Math.max(item.roomNum, totalPriceMax)
          })
          res.result.totalPriceMax = totalPriceMax
        }
        if (res.result.unitPriceList) {
          let unitPriceMax = 0
          res.result.unitPriceList.forEach(item => {
            unitPriceMax = Math.max(item.roomNum, unitPriceMax)
          })
          res.result.unitPriceMax = unitPriceMax
        }
        if (res.result.areaList) {
          let areaeMax = 0
          res.result.areaList.forEach(item => {
            areaeMax = Math.max(item.roomNum, areaeMax)
          })
          res.result.areaeMax = areaeMax
        }
        setSupplyData(res.result)
      }
    }
  }
  //  获取住宅库存数据
  const getStockData = async () => {
    const res = await getPlateStockCountAPI(stockDataQuery)
    if (res.success === true) {
      if (res.result) {
        if (res.result.totalPriceList) {
          let totalPriceMax = 0
          res.result.totalPriceList.forEach(item => {
            totalPriceMax = Math.max(item.roomNum, totalPriceMax)
          })
          res.result.totalPriceMax = totalPriceMax
        }
        if (res.result.unitPriceList) {
          let unitPriceMax = 0
          res.result.unitPriceList.forEach(item => {
            unitPriceMax = Math.max(item.roomNum, unitPriceMax)
          })
          res.result.unitPriceMax = unitPriceMax
        }
        if (res.result.areaList) {
          let areaeMax = 0
          res.result.areaList.forEach(item => {
            areaeMax = Math.max(item.roomNum, areaeMax)
          })
          res.result.areaeMax = areaeMax
        }
        setStockData(res.result)
      }
    }
  }
  //  日期范围选择器
  const disabledDate = current => {
    //  大于当月不可选
    return current && current > Dayjs().endOf('day')
  }

  //  表格列表翻页
  const onChange = (page, pageSize) => {
    tableListQuery.pageInt = page
    tableListQuery.pageSize = pageSize
    setTableListQuery({
      ...tableListQuery,
      pageInt: page,
      pageSize: pageSize
    })
    getHotHouseList()
  }
  //  住宅成交时间端切换
  const changeSoldQueryTime = (dates, dateStrings) => {
    let startTime = dateStrings[0] + '-01'
    let endYear = new Date(dateStrings[1]).getFullYear()
    let endMonth = new Date(dateStrings[1]).getMonth() + 1
    let endTime = ''
    if (endYear == curYear && endMonth == curMonth) {
      endTime = Dayjs().format('YYYY-MM-DD')
    } else {
      endTime = endYear + '-' + (endMonth < 10 ? '0' + endMonth : endMonth) + '-' + Dayjs(dateStrings[1]).daysInMonth()
    }
    soldDataQuery.startTime = startTime
    soldDataQuery.endTime = endTime
    setSoldDataQuery({
      ...soldDataQuery,
      startTime,
      endTime
    })
    getSoldData()
  }
  //  住宅供应时间端切换
  const changeSupplyQueryTime = (dates, dateStrings) => {
    let startTime = dateStrings[0] + '-01'
    let endYear = new Date(dateStrings[1]).getFullYear()
    let endMonth = new Date(dateStrings[1]).getMonth() + 1
    let endTime = ''
    if (endYear == curYear && endMonth == curMonth) {
      endTime = Dayjs().format('YYYY-MM-DD')
    } else {
      endTime = endYear + '-' + (endMonth < 10 ? '0' + endMonth : endMonth) + '-' + Dayjs(dateStrings[1]).daysInMonth()
    }
    supplyDataQuery.startTime = startTime
    supplyDataQuery.endTime = endTime
    setSupplyDataQuery({
      ...supplyDataQuery,
      startTime,
      endTime
    })
    getSupplyData()
  }
  //  住宅库存时间端切换
  // const changeStockQueryTime = (dates, dateStrings) => {
  //   let startTime = dateStrings[0] + '-01'
  //   let endYear = new Date(dateStrings[1]).getFullYear()
  //   let endMonth = new Date(dateStrings[1]).getMonth() + 1
  //   let endTime = ''
  //   if (endYear == curYear && endMonth == curMonth) {
  //     endTime = Dayjs().format('YYYY-MM-DD')
  //   } else {
  //     endTime = endYear + '-' + (endMonth < 10 ? '0' + endMonth : endMonth) + '-' + Dayjs(dateStrings[1]).daysInMonth()
  //   }
  //   stockDataQuery.startTime = startTime
  //   stockDataQuery.endTime = endTime
  //   setStockDataQuery({
  //     ...stockDataQuery,
  //     startTime,
  //     endTime
  //   })
  //   getStockData()
  // }
  //  切换板块
  const changePlate = id => {
    let path = '/managecenter/supplymarket/district?districtid=' + districtId + '&plateid=' + id
    navigate(path)
    setPlateId(id)
  }

  useEffect(() => {
    if (!totalDistrict) {
      getDistrictList()
    }
    fetchData(plateId)
    getHotHouseList()
    getSoldData()
    getSupplyData()
    getStockData()
  }, [plateId])

  return (
    <div>
      <Card className="card">
        <div className="card-tabs">
          {plateTabsList.map((item, index) => {
            return (
              <div key={index} className={plateId == item.id ? 'card-tabs-item card-tabs-item-active' : 'card-tabs-item'} onClick={() => changePlate(item.id)}>
                {item.name}
              </div>
            )
          })}
        </div>
        <Row gutter={[24]} style={{ marginTop: '24px' }}>
          <Col span={6}>
            <div className={styles['plateinfo-item'] + ' ' + styles[districtInfo.bgStyle]}>{plateId > 0 ? districtInfo.plateName : districtInfo.districtName}</div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">
                <div>月均成交</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{districtInfo.monthSoldNum}套</div>
              <div className="housedetail-info-item-desc">
                排名{districtInfo.monthSoldRank}/{plateId > 0 ? totalPlate : totalDistrict}，{districtInfo.monthSoldRankTxt}
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">
                <div>去化周期</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{districtInfo.soldCycle}月</div>
              <div className="housedetail-info-item-desc">
                排名{districtInfo.soldCycleRank}/{plateId > 0 ? totalPlate : totalDistrict}，{districtInfo.soldCycleRankTxt}
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">
                <div>活跃楼盘</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{districtInfo.dynamicHouseNum}个</div>
              <div className="housedetail-info-item-desc">
                排名{districtInfo.dynamicHouseRank}/{plateId > 0 ? totalPlate : totalDistrict}，{districtInfo.dynamicHouseRankTxt}
              </div>
            </div>
          </Col>
        </Row>
        <div className="hotHouseTitle">
          区域活跃楼盘 {districtInfo.districtName} {districtInfo.plateName}
        </div>
        <Table dataSource={hotHouseList} loading={tableLoading} pagination={false} rowKey="id">
          <Column title="楼盘名" dataIndex="name" />
          <Column title="区域" dataIndex="districtName" />
          <Column title="板块" dataIndex="plateName" />
          <Column title="参考价" dataIndex="price" />
          <Column title="最新取证" dataIndex="newEvidenceTime" />
          <Column title="取证均价" dataIndex="price" />
          <Column title="30天网签" dataIndex="newSoldNum" />
          <Column title="总网签" dataIndex="soldTotal" />
          <Column
            title="操作"
            key="action"
            render={(_, record) => (
              <Space size="middle">
                <Link to={'/managecenter/house/detail/' + record.id}>详情</Link>
              </Space>
            )}
          />
        </Table>
        <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={tableListQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={tableTotal} showSizeChanger showQuickJumper onChange={onChange} />
      </Card>
      <Card className="card">
        <div className="card-title">
          <div className="card-txt">住宅成交 {plateId > 0 ? districtInfo.plateName : districtInfo.districtName}</div>
          <div className="housedetail-signtabs">
            <RangePicker allowClear needConfirm picker="month" value={[Dayjs(soldDataQuery.startTime), Dayjs(soldDataQuery.endTime)]} disabledDate={disabledDate} style={{ width: '300px' }} onChange={changeSoldQueryTime} />
          </div>
        </div>
        <Row gutter={[24]} style={{ marginTop: '24px' }}>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>成交量</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{soldData.soldNum}套</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>成交面积</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{soldData.areaTotal}㎡</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>套均面积</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{soldData.areaAvg}㎡</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>成交量</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{soldData.soldNum}套</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">成交总价分布</div>
              <div className="roomlist-list">
                {soldData.totalPriceList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / soldData.totalPriceMax) * 100) + '% - ' + Math.round((item.roomNum / soldData.totalPriceMax) * 250) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">单价分布</div>
              <div className="roomlist-list">
                {soldData.unitPriceList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / soldData.unitPriceMax) * 100) + '% - ' + Math.round((item.roomNum / soldData.unitPriceMax) * 250) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">面积分布</div>
              <div className="roomlist-list">
                {soldData.areaList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / soldData.areaeMax) * 100) + '% - ' + Math.round((item.roomNum / soldData.areaeMax) * 250) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <Card className="card">
        <div className="card-title">
          <div className="card-txt">住宅供应 {plateId > 0 ? districtInfo.plateName : districtInfo.districtName}</div>
          <div className="housedetail-signtabs">
            <RangePicker allowClear needConfirm picker="month" value={[Dayjs(supplyDataQuery.startTime), Dayjs(supplyDataQuery.endTime)]} disabledDate={disabledDate} style={{ width: '300px' }} onChange={changeSupplyQueryTime} />
          </div>
        </div>
        <Row gutter={[24]} style={{ marginTop: '24px' }}>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>取证个数</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{supplyData.evidenceNum}个</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>取证房源</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{supplyData.roomTotalNum}套</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>总面积</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{supplyData.areaTotal}㎡</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>套均面积</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{supplyData.areaAvg}㎡</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">取证房源总价分布</div>
              <div className="roomlist-list">
                {supplyData.totalPriceList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / supplyData.totalPriceMax) * 100) + '% - ' + Math.round((item.roomNum / supplyData.totalPriceMax) * 200) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">单价分布</div>
              <div className="roomlist-list">
                {supplyData.unitPriceList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / supplyData.unitPriceMax) * 100) + '% - ' + Math.round((item.roomNum / supplyData.unitPriceMax) * 200) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">面积分布</div>
              <div className="roomlist-list">
                {supplyData.areaList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / supplyData.areaeMax) * 100) + '% - ' + Math.round((item.roomNum / supplyData.areaeMax) * 200) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <Card className="card">
        <div className="card-title">
          <div className="card-txt">住宅库存 {plateId > 0 ? districtInfo.plateName : districtInfo.districtName}</div>
          {/* <div className="housedetail-signtabs">
            <RangePicker allowClear needConfirm picker="month" value={[Dayjs(stockDataQuery.startTime), Dayjs(stockDataQuery.endTime)]} disabledDate={disabledDate} style={{ width: '300px' }} onChange={changeStockQueryTime}></RangePicker>
          </div> */}
        </div>
        <Row gutter={[24]} style={{ marginTop: '24px' }}>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>报规库存</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{stockData.stockNum}套</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>去化周期</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{stockData.soldCycle}月</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>取证库存</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{stockData.evidenceRoomNum}套</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item" style={{ paddingBottom: '0' }}>
              <div className="housedetail-info-item-title">
                <div>去化周期</div>
                <div className="iconfont icon-info-circle" />
              </div>
              <div className="housedetail-info-item-num">{stockData.evidenceSoldCycle}月</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">取证房源总价分布</div>
              <div className="roomlist-list">
                {stockData.totalPriceList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / stockData.totalPriceMax) * 100) + '% - ' + Math.round((item.roomNum / stockData.totalPriceMax) * 200) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">单价分布</div>
              <div className="roomlist-list">
                {stockData.unitPriceList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / stockData.unitPriceMax) * 100) + '% - ' + Math.round((item.roomNum / stockData.unitPriceMax) * 200) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="roomlist-count">
              <div className="roomlist-title">面积分布</div>
              <div className="roomlist-list">
                {stockData.areaList.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.roomNum / stockData.areaeMax) * 100) + '% - ' + Math.round((item.roomNum / stockData.areaeMax) * 200) + 'px)' }} />
                      <div className="roomlist-item-num">{item.roomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}
