//  axios的封装处理
import axios from 'axios'
import { getToken } from './token'
//  1. 根域名配置
//  2. 超时时间
//  3. 请求拦截器 / 响应拦截器

const request = axios.create({
  baseURL: 'https://biz.wufangtong.com',
  timeout: 30000
})

//  添加请求拦截器
//  在请求发送之前做拦截，插入一些自定义的配置[参数的处理]
request.interceptors.request.use(
  config => {
    console.log('%c [ 请求地址 ]-16', 'font-size:13px; background:pink; color:#bf2c9f;', config.url)
    console.log('%c [ 请求参数 ]-16', 'font-size:13px; background:pink; color:#bf2c9f;', config.data)
    //  操作config注入token数据
    const token = getToken()
    if (token) {
      config.headers.token = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//  添加响应拦截器
//  在响应返回到客户端之前做拦截，重点处理返回的数据
request.interceptors.response.use(
  response => {
    //  2xx 范围内的状态码都会触发该函数。
    //  对响应数据做点什么
    return response.data
  },
  error => {
    //  超出 2xx 范围的状态码都会触发该函数。
    //  对响应错误做点什么
    return Promise.reject(error)
  }
)

export { request }
